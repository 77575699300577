import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TablePagination,
  TableRow,
  Card,
} from '@material-ui/core';

import { listItems } from 'utils/scripts';
import Footer from './components/Footer';
import Header from './components/Header';
import RowItem from './components/RowItem';

import {
  toArray,
  omitBy,
  map,
  isUndefined,
  concat,
  isArray,
  pick,
  lowerCase,
  without,
  get,
  size,
  includes,
} from 'lodash';
import styles from './styles';

class ListItems extends React.Component {
  _isMounted = false;

  componentWillUnmount() {
    this._isMounted = false;
  }

  state = {
    page: 0,
    rows: [],
    rowsToShow: [],
    rowsPerPage: 5,
    order: 'desc',
    orderBy: '',
    search: '',
    contentLabels: [],
  };

  async componentDidMount() {
    this._isMounted = true;

    this._isMounted &&
      this.setState({
        orderBy: this.props.orderBy,
        contentLabels: toArray(
          omitBy(map(this.props.headCells, 'id'), isUndefined)
        ),
        pick: concat(
          toArray(omitBy(map(this.props.headCells, 'id'), isUndefined)),
          isArray(this.props.pick) ? this.props.pick : []
        ),
      });

    const converter = {
      toFirestore: (value) => value,
      fromFirestore: (snapshot, options) => {
        const { name } = snapshot.data();
        return { name };
      },
    };

    this.props.collection &&
      (await listItems(
        this.props.collection,
        this.props.filter,
        (rows) => {
          const data = map(rows, (row) =>
            this.state.pick ? pick(row, this.state.pick) : row
          );

          this.filter(data);
        },
        converter
      ));
  }

  componentDidUpdate(prevProps) {
    if (prevProps.search !== this.props.search) {
      this.filter();
    }

    if (prevProps.rows !== this.props.rows) {
      this.filter(this.props.rows);
    }
  }

  handleRequestSort = (event, property) => {
    const { order, orderBy } = this.state;
    const isAsc = orderBy === property && order === 'asc';
    this.setState({ order: isAsc ? 'desc' : 'asc', orderBy: property });
  };

  handleChangePage = (target, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = ({ target }) => {
    this.setState({ page: 0, rowsPerPage: parseInt(target.value, 10) });
  };

  stableSort = (array, cmp) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };

  desc = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  getSorting = (order, orderBy) => {
    return order === 'desc'
      ? (a, b) => this.desc(a, b, orderBy)
      : (a, b) => -this.desc(a, b, orderBy);
  };

  async filter(rows = false) {
    this._isMounted && rows && this.setState({ rows });

    const search = lowerCase(this.props.search);

    const rowsToShow = search
      ? without(
          map(
            get(this.state, 'rows', []),
            (row) =>
              size(
                without(
                  map(get(this.state, 'contentLabels', []), (label) =>
                    includes(lowerCase(get(row, label, '')), search)
                  ),
                  false
                )
              ) > 0 && row
          ),
          false
        )
      : this.state.rows;

    this._isMounted && this.setState({ rowsToShow });
  }

  render() {
    const { classes } = this.props;
    const { rowsPerPage, page, order, orderBy, rowsToShow } = this.state;
    const emptyRows =
      rowsPerPage -
      Math.min(rowsPerPage, rowsToShow.length - page * rowsPerPage);

    return (
      <TableContainer component={Card} elevation={1}>
        <Table className={classes.table} size="small">
          <Header
            headCells={this.props.headCells}
            order={order}
            orderBy={orderBy}
            onRequestSort={this.handleRequestSort}
          />

          <TableBody>
            {(rowsPerPage > 0
              ? this.stableSort(
                  rowsToShow,
                  this.getSorting(order, orderBy)
                ).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : rowsToShow
            ).map((row) => (
              <RowItem
                row={row}
                key={row.uid}
                firstCell={this.props.firstCell}
                contentLabels={this.state.contentLabels}
                headCells={this.props.headCells}
                Actions={this.props.Actions}
              />
            ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 50 * emptyRows }}>
                <TableCell colSpan={size(this.props.headCells)} />
              </TableRow>
            )}
          </TableBody>

          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                colSpan={size(this.props.headCells)}
                count={rowsToShow.length}
                rowsPerPage={rowsPerPage}
                page={page}
                labelDisplayedRows={({ from, to, count }) =>
                  `${from}-${to === -1 ? count : to} of ${count}`
                }
                labelRowsPerPage={this.props.labelRowsPerPage}
                onPageChange={this.handleChangePage}
                onRowsPerPageChange={this.handleChangeRowsPerPage}
                ActionsComponent={Footer}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    );
  }
}

export default styles(ListItems);
