import React from 'react';
import { withTheme } from '@material-ui/core/styles';
import {
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
} from '@material-ui/core';

import styles from './styles';

class EnhancedTableHead extends React.Component {
  state = {};

  createSortHandler = (property) => (event) => {
    const { onRequestSort } = this.props;
    onRequestSort(event, property);
  };

  render() {
    const { classes, order, orderBy, headCells } = this.props;

    return (
      <TableHead>
        <TableRow className={classes.header}>
          {headCells.map((headCell, key) => {
            if (headCell.none) {
              return <th key={key} />;
            }

            if (headCell.empty) {
              return (
                <TableCell key={key}>
                  <strong>{headCell.label || ''}</strong>
                </TableCell>
              );
            }

            if (!headCell.empty) {
              return (
                <TableCell
                  key={key}
                  colSpan={headCell.colSpan}
                  align={headCell.numeric ? 'right' : 'left'}
                  padding={headCell.disablePadding ? 'none' : 'normal'}
                  sortDirection={orderBy === headCell.id ? order : false}
                  className={classes.text}
                >
                  <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : 'asc'}
                    onClick={this.createSortHandler(headCell.id)}
                  >
                    <strong>{headCell.label || ''}</strong>
                  </TableSortLabel>
                </TableCell>
              );
            }

            return <th key={key} style={{ display: 'none' }} />;
          })}
        </TableRow>
      </TableHead>
    );
  }
}

export default withTheme(styles(EnhancedTableHead));
