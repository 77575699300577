import { SET_VALUES } from 'actions';

export default (
  state = {
    values: {},
  },
  action
) => {
  switch (action.type) {
    case SET_VALUES:
      return {
        ...state,
        values: action.values,
      };
    default:
      return state;
  }
};
