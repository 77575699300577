export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR';
export const OPEN_SNACKBAR = 'OPEN_SNACKBAR';

export const closeSnackbar = () => {
  return {
    type: CLOSE_SNACKBAR,
  };
};

export const openSnackbar = (message, severity) => {
  return {
    type: OPEN_SNACKBAR,
    message,
    severity,
  };
};
