import {
  CHANGE_MENU,
  SHOW_FOOTER,
  SHOW_TOOLBAR,
  CHANGE_MAXWIDTH,
  UPDATE_VIEW,
} from 'actions';

export default (
  state = {
    menuToShow: 'none',
    showFooter: false,
    showToolbar: false,
    maxWidth: 'lg',
  },
  action
) => {
  switch (action.type) {
    case UPDATE_VIEW:
      return {
        ...state,
        menuToShow: action.menu,
        showToolbar: action.showToolbar,
        showFooter: action.showFooter,
        maxWidth: action.maxWidth,
      };
    case CHANGE_MENU:
      return {
        ...state,
        menuToShow: action.menu,
      };
    case CHANGE_MAXWIDTH:
      return {
        ...state,
        maxWidth: action.maxWidth,
      };
    case SHOW_FOOTER:
      return {
        ...state,
        showFooter: action.show,
      };
    case SHOW_TOOLBAR:
      return {
        ...state,
        showToolbar: action.show,
      };
    default:
      return state;
  }
};
