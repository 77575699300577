import { createFile } from './index';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/functions';
const firestore = firebase.firestore();
const functions = firebase.functions();

export const createBuilder = async (builderData) =>
  functions
    .httpsCallable('CreateBuilder')(builderData)
    .catch((e) => console.error('CreateBuilder', e.message || e)) || false;

export const setNewLogoPhoto = async (builderData) => {
  if (!builderData) {
    return false;
  }

  const builderId = builderData.uid;
  const builder = firestore.doc(`builders/${builderId}/`);

  const photoProfile = await createFile(
    'photoProfile',
    'image/png, image/jpeg',
    builderId,
    builderId
  );

  if (photoProfile) {
    await builder
      .update({
        photoProfile,
      })
      .catch((_) => {});

    return true;
  }

  return false;
};
