import { withStyles } from '@material-ui/core/styles';

export default withStyles((theme) => ({
  root: {
    margin: theme.spacing(4),
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing(2),
    padding: theme.spacing(3, 4, 4),
  },
  iconItem: {
    marginLeft: theme.spacing(2),
    color: `${theme.palette.primary.main}80`,
  },
}));
