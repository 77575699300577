import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import { openSnackbar } from './index';
import { get, assign } from 'lodash';
import { configlab } from 'utils/configlab';

const firestore = firebase.firestore();
const auth = firebase.auth();
let builderId = false;

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';

export const VERIFY_REQUEST = 'VERIFY_REQUEST';
export const VERIFY_SUCCESS = 'VERIFY_SUCCESS';

export const UPDATE_ORGANIZATION = 'UPDATE_ORGANIZATION';

const requestLogin = () => {
  return {
    type: LOGIN_REQUEST,
  };
};

const receiveLogin = (userData) => {
  userData.builderId = builderId || userData.builderId || false;
  return {
    type: LOGIN_SUCCESS,
    userData,
  };
};

const updateBuilder = (builder = {}) => {
  const favicon = document.getElementById('favicon');
  favicon.href = builder.favicon;

  window.builder = () => ({
    data: (toPick) => configlab({ data: builder }).builder(toPick),
  });

  return {
    type: UPDATE_ORGANIZATION,
    builder,
  };
};

const loginError = () => {
  return {
    type: LOGIN_FAILURE,
  };
};

const requestLogout = () => {
  return {
    type: LOGOUT_REQUEST,
  };
};

const receiveLogout = () => {
  return {
    type: LOGOUT_SUCCESS,
  };
};

const logoutError = () => {
  return {
    type: LOGOUT_FAILURE,
  };
};

const verifyRequest = () => {
  return {
    type: VERIFY_REQUEST,
  };
};

const verifySuccess = () => {
  return {
    type: VERIFY_SUCCESS,
  };
};

export const loginUserWithEmail = (email, password) => (dispatch) => {
  dispatch(requestLogin());
  auth
    .signInWithEmailAndPassword(email, password)
    .then((user) => {
      dispatch(receiveLogin(user));
    })
    .catch((error) => {
      console.error('[ERROR]', error.message || error);
      //Do something with the error if you want!
      dispatch(loginError());
    });
};

export const loginUserWithProvider = (providerName) => (dispatch) => {
  dispatch(requestLogin());
  let provider;
  switch (providerName) {
    case 'google':
      provider = new firebase.auth.GoogleAuthProvider();
      break;
    case 'microsoft':
      provider = new firebase.auth.OAuthProvider('microsoft.com');
      break;
    default:
      provider = false;
  }

  if (!provider) {
    return false;
  }

  auth
    .signInWithPopup(provider)
    .then((user) => {
      if (user.additionalUserInfo.isNewUser) {
        user.user.delete();
        dispatch(openSnackbar('Your Google account does not exist', 'error'));
        dispatch(loginError());
        return false;
      }
      dispatch(receiveLogin(user));
    })
    .catch((error) => {
      //Do something with the error if you want!
      dispatch(loginError());
    });
};

export const resetPassword = (email) => (dispatch) => {
  auth
    .sendPasswordResetEmail(email)
    .then((user) => {})
    .catch((error) => {});
};

export const logoutUser = () => (dispatch) => {
  dispatch(requestLogout());
  auth
    .signOut()
    .then(() => {
      dispatch(receiveLogout());
    })
    .catch((error) => {
      //Do something with the error if you want!
      dispatch(logoutError());
    });
};

export const verifyAuth = () => async (dispatch) => {
  dispatch(verifyRequest());

  const builderDomain = ['localhost:8000', '192.168.1.5:8000'].includes(
    window.location.host
  )
    ? 'dev.configlab.co'
    : window.location.host;

  firestore
    .collection('builders')
    .where('domain', '==', builderDomain)
    .onSnapshot(async (builders) => {
      if (builders.empty) {
        dispatch(updateBuilder(null));
        return false;
      } else {
        const builder = get(builders, 'docs[0]');
        builderId = builder.id;
        dispatch(
          updateBuilder(
            assign(builder.data(), {
              builderId: builder.id,
            })
          )
        );
      }
    });

  auth.onAuthStateChanged(async (user) => {
    if (user !== null) {
      firestore.doc(`users/${user.uid}`).onSnapshot(
        async (doc) => {
          if (doc.exists) {
            const userData = assign(doc.data(), {
              uid: user.uid,
            });

            window.user = () => ({
              data: (toPick) => configlab({ data: userData }).builder(toPick),
            });

            dispatch(receiveLogin(userData));
            dispatch(verifySuccess());
          } else {
            dispatch(logoutUser());
          }
        },
        (error) => console.error(error)
      );
    } else {
      dispatch(verifySuccess());
    }
  });
};
