import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';
import { v4 as uuidv4 } from 'uuid';
import nanoId from 'utils/nanoId';

const storage = firebase.storage();
const firestore = firebase.firestore();

export const deleteFile = async (builderId, fileId) => {
  if (!fileId) {
    return false;
  }

  try {
    await firestore.doc(`builders/${builderId}/files/${fileId}`).delete();
  } catch (_) {
    return false;
  }

  return true;
};

export const updateMetadataFile = async (builderId, fileId, metadata) => {
  if (!fileId || !metadata) {
    return false;
  }

  try {
    await firestore
      .doc(`builders/${builderId}/files/${fileId}`)
      .update(metadata);
  } catch (_) {
    return false;
  }

  return true;
};

export const downloadLink = async (builderId, fileId) => {
  if (fileId === false) {
    return false;
  }

  return storage
    .ref(`files/${builderId}/${fileId}`)
    .getDownloadURL()
    .catch((error) => console.error(error));
};

export const listFiles = async (user, type) => {
  let files = [];

  if (!user || !type) {
    return files;
  }

  try {
    const data = await firestore
      .collection('files')
      .where('user', '==', user)
      .where('type', '==', type)
      .get();
    files = data.docs.map((doc) => ({
      uid: doc.id,
      ...doc.data(),
    }));
  } catch (_) {
    files = [];
  }

  return files;
};

export const createFile = (type, accept, userId, builderId, email) => {
  if (!type || !accept || !userId || !builderId) {
    return false;
  }

  document.createElement('input');

  const fileId = nanoId();

  const input = document.createElement('input');
  input.setAttribute('type', 'file');
  input.setAttribute('id', fileId);
  input.setAttribute('accept', accept || '');
  input.click();

  return new Promise((resolve) => {
    input.addEventListener('change', () => {
      const file = input.files[0];

      const metadata = {
        gzip: true,
        contentType: file.type,
        cacheControl: 'public,max-age=31536000',
        firebaseStorageDownloadTokens: uuidv4(),
        customMetadata: {
          size: file.size,
          name: file.name,
          userId,
          email,
          builderId,
          type: type || 'userData',
        },
      };

      storage
        .ref()
        .child(`files/${builderId}/${fileId}-${file.name}`)
        .put(file, metadata)
        .then(() => {
          input.remove();

          resolve(fileId);
        })
        .catch((_) => resolve(false));
    });
  });
};

export const getFile = async (fileId) => {
  if (!fileId) {
    return {};
  }

  let file;
  try {
    const data = await firestore.doc(`files/${fileId}`).get();

    file = {
      uid: data.id,
      ...data.data(),
    };
  } catch (_) {
    file = {};
  }

  return file;
};
