import React from 'react';
import ReactDOM from 'react-dom';
import initScripts from 'utils/initScripts';
import configureStore from 'utils/configureStore';
import App from 'containers/App';
import './index.css';

window.version = 1;
initScripts();

const store = configureStore();

ReactDOM.render(<App store={store} />, document.getElementById('root'));
