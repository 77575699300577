import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import Routes from '../../routes';
import { ReactReduxFirebaseProvider } from 'react-redux-firebase';
import { createFirestoreInstance } from 'redux-firestore';
import { defaultRRFConfig } from '../../defaultConfig';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import firebase from 'firebase/app';

function App(props) {
  const { store } = props;

  // add vieport head to the document
  useEffect(() => {
    const meta = document.createElement('meta');
    meta.name = 'viewport';
    // prevent zooming on mobile devices
    meta.content =
      'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0';
    document.getElementsByTagName('head')[0].appendChild(meta);
  }, []);

  return (
    <Provider store={store}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Router>
          <ReactReduxFirebaseProvider
            firebase={firebase}
            config={defaultRRFConfig}
            dispatch={store.dispatch}
            createFirestoreInstance={createFirestoreInstance}
          >
            <Routes />
          </ReactReduxFirebaseProvider>
        </Router>
      </MuiPickersUtilsProvider>
    </Provider>
  );
}
export default App;
