import React from 'react';
import { withTheme } from '@material-ui/core/styles';
import { TableRow, TableCell } from '@material-ui/core';

import styles from './styles';
import { keys, map, get } from 'lodash';

class TableRowItem extends React.Component {
  state = {};

  render() {
    const { classes, row, firstCell, headCells, Actions } = this.props;
    const total = headCells.length - 1;

    if (keys(row).length === 0) {
      return <div />;
    }

    return (
      <TableRow className={classes.row} hover>
        {map(headCells, (header, key) => {
          if (key === 0 && header.empty) {
            return (
              <TableCell key={key} style={header.style || {}}>
                {firstCell}
              </TableCell>
            );
          }

          if (key === total) {
            return Actions ? (
              <TableCell key={key} style={header.style || {}}>
                {Actions(row)}
              </TableCell>
            ) : (
              <td key={key} style={{ display: 'none' }} />
            );
          }

          return (
            <TableCell key={key} style={header.style || {}}>
              {(header.format
                ? header.format(get(row, header.id), row)
                : get(row, header.id)) || '-'}
            </TableCell>
          );
        })}
      </TableRow>
    );
  }
}

export default withTheme(styles(TableRowItem));
