import { OPEN_SNACKBAR, CLOSE_SNACKBAR } from 'actions';

export default (
  state = {
    message: '',
    open: false,
    type: 'info',
  },
  action
) => {
  switch (action.type) {
    case OPEN_SNACKBAR:
      return {
        ...state,
        message: action.message,
        open: true,
        severity: action.severity,
      };
    case CLOSE_SNACKBAR:
      return {
        ...state,
        message: '',
        open: false,
        type: 'info',
      };
    default:
      return state;
  }
};
