import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { CircularProgress } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import PrivateRoute from './components/PrivateRoute';
import PublicRoute from './components/PublicRoute';
import BothRoute from './components/BothRoute';
import NotFound from 'pages/NotFound';
import appRoutes from 'routes/routes';
import { filter } from 'lodash';
import { logoutUser } from 'actions';
import CustomTheme from 'utils/customTheme';
import styles from './styles';

const Routes = (props) => {
  const { isAuthenticated, isVerifying, classes, userData, builder, dispatch } =
    props;

  useEffect(() => {
    if (
      userData &&
      userData.builderId !== false &&
      userData.role !== 'user' &&
      builder.builderId &&
      builder.builderId !== userData.builderId &&
      isAuthenticated &&
      !isVerifying
    ) {
      dispatch(logoutUser());
    }
  }, [userData, builder, isAuthenticated, isVerifying, dispatch]);

  useEffect(() => {
    document.title = builder?.companyName || '';
  }, [builder]);

  if (!builder) {
    return <React.Fragment />;
  }

  if (isVerifying) {
    return (
      <div className={classes.root}>
        <CircularProgress size={50} thickness={4} />
      </div>
    );
  }

  return (
    <MuiThemeProvider theme={CustomTheme(builder)}>
      <Switch>
        {filter(appRoutes, { private: true }).map((route) => (
          <PrivateRoute
            exact
            key={route.id}
            path={route.path}
            currentRole={userData.role}
            {...{ isVerifying, isAuthenticated, route }}
          />
        ))}

        {filter(appRoutes, { private: false }).map((route) => (
          <PublicRoute
            exact
            key={route.id}
            path={route.path}
            {...{ isVerifying, isAuthenticated, route }}
          />
        ))}

        {filter(appRoutes, { private: null }).map((route) => (
          <BothRoute
            exact
            key={route.id}
            path={route.path}
            currentRole={userData.role}
            {...{ isVerifying, isAuthenticated, route }}
          />
        ))}

        <Route component={NotFound} />
      </Switch>
    </MuiThemeProvider>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  isVerifying: state.auth.isVerifying,
  userData: state.auth.userData,
  builder: state.auth.builder,
});

export default connect(mapStateToProps)(withRouter(styles(Routes)));
