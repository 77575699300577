import React from 'react';
import { withTheme } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';

import styles from './styles';

class TablePaginationActions extends React.Component {
  state = {};

  handleFirstPageButtonClick = ({ target }) => {
    const { onPageChange } = this.props;
    onPageChange(target, 0);
  };

  handleBackButtonClick = ({ target }) => {
    const { page, onPageChange } = this.props;
    onPageChange(target, page - 1);
  };

  handleNextButtonClick = ({ target }) => {
    const { page, onPageChange } = this.props;
    onPageChange(target, page + 1);
  };

  handleLastPageButtonClick = ({ target }) => {
    const { count, rowsPerPage, onPageChange } = this.props;
    onPageChange(target, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  render() {
    const { classes, page, count, rowsPerPage, theme } = this.props;

    return (
      <div className={classes.root}>
        <IconButton
          onClick={this.handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={this.handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === 'rtl' ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={this.handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === 'rtl' ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={this.handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    );
  }
}

export default withTheme(styles(TablePaginationActions));
