/**
 * NOTE: This file is ignored from git tracking. In a CI environment it is
 * generated by firebase-ci based on config in .firebaserc (see .gitlab-ci.yaml).
 * This is done so that environment specific settings can be applied.
 */
export const env = 'dev';
// Config for firebase
export const firebaseConfig = {
  apiKey: 'AIzaSyDKEIeGxEfSJbMvrJx647dH5McfD5ZlmyA',
  authDomain: 'configlab-stage.firebaseapp.com',
  databaseURL: 'https://configlab-stage.firebaseio.com',
  projectId: 'configlab-stage',
  storageBucket: 'configlab-stage.appspot.com',
  messagingSenderId: '181046787574',
  appId: '1:181046787574:web:2fa0031b71faf4a228dbd7',
  measurementId: 'G-VETLBLK3LP',
};

export const firebaseConfigDev = {
  apiKey: 'AIzaSyApb20Tkb6j80Ez11uHSxsRS6cWmQvEFdU',
  authDomain: 'configlab-dev.firebaseapp.com',
  projectId: 'configlab-dev',
  storageBucket: 'configlab-dev.appspot.com',
  messagingSenderId: '595650405535',
  appId: '1:595650405535:web:e4b483280de54de6c02eaa',
  measurementId: 'G-PC2S4JN8E1',
};

export const stripeApiKey = 'pk_test_66at4fWRVVyqE9a1CR9K2TW000qwsRHiQZ';
