import { createFile } from './index';

import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/functions';

const functions = firebase.functions();
const firestore = firebase.firestore();

const PublishMessage = functions.httpsCallable('PublishMessage');

export const sendEmail = (message) => {
  return PublishMessage({
    topic: 'create-report',
    message,
  });
};

export const CreateStripePayment = (data) => {
  const send = functions.httpsCallable('CreateStripePayment');
  return send(data);
};

export const CreateUserSync = (data) => {
  const send = functions.httpsCallable('CreateUserSync');
  return send(data);
};

export const SendEmailVerification = (email, firstName) => {
  const send = functions.httpsCallable('SendEmailVerification');
  return send({
    email,
    firstName,
  });
};

export const SendPasswordResetEmail = (email) => {
  const send = functions.httpsCallable('SendPasswordResetEmail');
  return send({
    email,
  });
};

export const cronTesting = (topic, message) => {
  return PublishMessage({
    topic,
    message,
  });
};

export const createUser = (message) => {
  return PublishMessage({
    topic: 'create-user',
    message,
  });
};

export const deleteUser = (user) => {
  const remove = functions.httpsCallable('DeleteUser');
  const { uid, email } = user;

  if (!(uid || email)) {
    return false;
  }

  return remove({
    uid: user.uid || undefined,
    email: user.email || undefined,
  });
};

export const updateUserData = (user, userData) => {
  const update = functions.httpsCallable('UpdateUser');
  const { uid, email } = user;

  if (!((uid || email) && typeof userData === 'object')) {
    return false;
  }

  return update({
    uid,
    email,
    userData,
  });
};

export const disabledUser = (user, disabled) => {
  const update = functions.httpsCallable('UpdateUser');
  const { uid, email } = user;

  if (!((uid || email) && typeof disabled === 'boolean')) {
    return false;
  }

  return update({
    uid,
    email,
    disabled,
  });
};

export const setEmailVerifiedUser = (user, emailVerified) => {
  const update = functions.httpsCallable('UpdateUser');
  const { uid, email } = user;

  if (!((uid || email) && typeof emailVerified === 'boolean')) {
    return false;
  }

  return update({
    uid,
    email,
    emailVerified,
  });
};

export const setNewPasswordUser = (user, password) => {
  const update = functions.httpsCallable('UpdateUser');
  const { uid, email } = user;

  if (
    !((uid || email) && typeof password === 'string' && password.length >= 6)
  ) {
    return null;
  }

  return update({
    uid,
    email,
    password,
  });
};

export const setNewEmailUser = (user, newEmail) => {
  const update = functions.httpsCallable('UpdateUser');
  const { uid, email } = user;

  if (!((uid || email) && typeof newEmail === 'string')) {
    return false;
  }

  return update({
    uid,
    email,
    newEmail,
  });
};

export const setNewPhotoProfile = async (userData) => {
  if (!userData) {
    return false;
  }

  const user = firestore.doc(
    `builders/${userData.builder}/${userData.role}s/${userData.uid}`
  );

  const photoProfile = await createFile(
    'photoProfile',
    'image/png, image/jpeg',
    userData.uid,
    userData.builder
  );

  if (photoProfile) {
    await user
      .update({
        photoProfile,
      })
      .catch(() => {});
    return true;
  }

  return false;
};

export const listUsers = async (builder, role) => {
  let users = [];

  if (!builder || !role) {
    return users;
  }

  try {
    const data = await firestore
      .collection(`builders/${builder}/${role}s/`)
      .get();
    users = data.docs.map((doc) => ({
      uid: doc.id,
      ...doc.data(),
    }));
  } catch (_) {
    users = [];
  }

  return users;
};

export const listUsersRealTime = async (builder, role, setUsers) => {
  if (!builder || !role) {
    setUsers([]);
    return;
  }

  firestore.collection(`builders/${builder}/${role}s/`).onSnapshot(
    (snapshot) => {
      const users = [];

      snapshot.forEach((doc) =>
        users.push({
          uid: doc.id,
          ...doc.data(),
        })
      );

      setUsers(users);
    },
    (error) => console.error(error)
  );
};

export const listItems = async (
  collection,
  filters = [],
  setItems,
  converter
) => {
  if (!collection) {
    setItems([]);
    return;
  }

  const fc = firestore
    .collection(collection)
    .orderBy('created', 'desc')
    .limit(200);

  let db;

  switch (filters.length) {
    case 0:
      db = fc;
      break;
    case 1:
      db = fc.where(filters[0][0], filters[0][1], filters[0][2]);
      break;
    case 2:
      db = fc
        .where(filters[0][0], filters[0][1], filters[0][2])
        .where(filters[1][0], filters[1][1], filters[1][2]);
      break;
    case 3:
      db = fc
        .where(filters[0][0], filters[0][1], filters[0][2])
        .where(filters[1][0], filters[1][1], filters[1][2])
        .where(filters[2][0], filters[2][1], filters[2][2]);
      break;
    default:
      db = fc;
      break;
  }

  if (converter) {
    db.withConverter(converter);
  }

  db.onSnapshot(
    (snapshot) => {
      const users = [];

      snapshot.forEach((doc) =>
        users.push({
          uid: doc.id,
          ...doc.data(),
        })
      );

      setItems(users);
    },
    (error) => {
      console.error(error);
      setItems([]);
    }
  );
};
