export const CHANGE_MENU = 'CHANGE_MENU';
export const SHOW_TOOLBAR = 'SHOW_TOOLBAR';
export const SHOW_FOOTER = 'SHOW_FOOTER';
export const CHANGE_MAXWIDTH = 'CHANGE_MAXWIDTH';
export const UPDATE_VIEW = 'UPDATE_VIEW';

export const changeMenu = (menu) => {
  return {
    type: CHANGE_MENU,
    menu,
  };
};

export const showToolbar = (show) => {
  return {
    type: SHOW_TOOLBAR,
    show,
  };
};

export const showFooter = (show) => {
  return {
    type: SHOW_FOOTER,
    show,
  };
};

export const changeMaxWidth = (maxWidth) => {
  return {
    type: CHANGE_MAXWIDTH,
    maxWidth,
  };
};

export const updateView = (showToolbar, showFooter, maxWidth, menu) => {
  return {
    type: UPDATE_VIEW,
    showToolbar,
    showFooter,
    maxWidth,
    menu,
  };
};
