import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';

const styles = {
  loader: {
    position: 'absolute',
    left: 'calc(50% - 30px)',
    top: 'calc(50% - 30px)',
  },
};

const PublicRoute = ({ isVerifying, isAuthenticated, route, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isVerifying ? (
        <CircularProgress style={styles.loader} size={60} />
      ) : isAuthenticated ? (
        <Redirect to="/orders" />
      ) : (
        <route.layout>
          <route.component {...props} route={route} />
        </route.layout>
      )
    }
  />
);

export default PublicRoute;
