import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';

const styles = {
  loader: {
    position: 'absolute',
    left: 'calc(50% - 30px)',
    top: 'calc(50% - 30px)',
  },
};

const PrivateRoute = ({
  isAuthenticated,
  isVerifying,
  currentRole,
  route,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) =>
      isVerifying ? (
        <CircularProgress style={styles.loader} size={60} />
      ) : isAuthenticated ? (
        route.rolesWithAccess.includes(currentRole) ? (
          <route.layout>
            <route.component {...props} route={route} />
          </route.layout>
        ) : props.location.pathname !== '/orders' ? (
          <Redirect
            to={{
              pathname: '/orders',
              state: { from: props.location },
            }}
          />
        ) : (
          <CircularProgress style={styles.loader} size={60} />
        )
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

export default PrivateRoute;
