import Login from 'pages/Login';
import ResetPassword from 'pages/ResetPassword';

import BuilderList from 'pages/Builders';
import BuilderReader from 'pages/Builders/Read';
import BuilderCreate from 'pages/Builders/Create';
import BuilderPage from 'pages/Builder';

import UserList from 'pages/Users/Users';
import UserReader from 'pages/Users/Read';
import UserCreate from 'pages/Users/Create';

import ModelList from 'pages/Models';
import ModelReader from 'pages/Models/Read';
import ModelCreate from 'pages/Models/Create';

import OrderList from 'pages/Orders';
import OrderReader from 'pages/Orders/Read';

import AdminList from 'pages/Admins';
import AdminReader from 'pages/Admins/Read';
import AdminCreate from 'pages/Admins/Create';

import TermsPage from 'pages/Terms';
import PrivacyPage from 'pages/Privacy';
import ProfilePage from 'pages/Profile';

import CatalogPage from 'pages/Catalog';

import ViewPage from 'pages/View';
import SummaryPdf from 'pages/Pdfs/Summary';
import DrawingPdf from 'pages/Pdfs/Drawing';

import CoreLayout from 'layouts/CoreLayout';
import PdfLayout from 'layouts/PdfLayout';
import ModelLayout from 'layouts/ModelLayout';

export default [
  {
    title: 'Summary PDF',
    id: 'summary-pdf',
    layout: PdfLayout,
    private: null,
    component: SummaryPdf,
    path: '/pdf/summary/:modelId',
    rolesWithAccess: [],
    defaultMenu: 'none',
  },
  {
    title: 'Drawing PDF',
    id: 'drawing-pdf',
    layout: PdfLayout,
    private: null,
    showInMenu: false,
    component: DrawingPdf,
    path: '/pdf/drawing/:modelId',
    rolesWithAccess: [],
    defaultMenu: 'none',
    showToolbar: false,
    showFooter: false,
    maxWidth: false,
  },
  {
    title: 'View Model',
    id: 'view',
    layout: ModelLayout,
    private: null,
    showInMenu: false,
    component: ViewPage,
    path: '/view/:modelId',
    rolesWithAccess: [],
    defaultMenu: 'none',
    showToolbar: false,
    showFooter: false,
    maxWidth: false,
  },
  {
    title: 'Catalog',
    id: 'catalog',
    layout: CoreLayout,
    private: null,
    showInMenu: false,
    component: CatalogPage,
    path: '/catalog',
    rolesWithAccess: [],
    defaultMenu: 'none',
    showToolbar: true,
    showFooter: false,
    maxWidth: 'lg',
  },
  {
    title: 'Login',
    id: 'login',
    layout: CoreLayout,
    private: false,
    showInMenu: false,
    component: Login,
    path: '/login',
    rolesWithAccess: [],
    defaultMenu: 'none',
    showToolbar: true,
    showFooter: false,
    maxWidth: 'lg',
  },
  {
    title: 'Reset Password',
    id: 'reset-password',
    layout: CoreLayout,
    private: false,
    showInMenu: false,
    component: ResetPassword,
    path: '/reset-password',
    rolesWithAccess: [],
    defaultMenu: 'none',
    showToolbar: true,
    showFooter: false,
    maxWidth: 'lg',
  },
  {
    title: 'Orders',
    id: 'orders-list',
    layout: CoreLayout,
    tab: 'orders',
    icon: 'local_shipping',
    private: true,
    showInMenu: true,
    component: OrderList,
    path: '/orders',
    rolesWithAccess: ['admin', 'user', 'superadmin'],
    defaultMenu: 'compact',
    showToolbar: true,
    showFooter: false,
    maxWidth: 'lg',
  },
  {
    title: 'Order Reader',
    id: 'order-reader',
    layout: CoreLayout,
    tab: 'orders',
    private: true,
    showInMenu: false,
    component: OrderReader,
    path: '/orders/:orderId',
    rolesWithAccess: ['admin', 'user', 'superadmin'],
    defaultMenu: 'compact',
    showToolbar: true,
    showFooter: false,
    maxWidth: 'md',
  },
  {
    title: 'Builders',
    id: 'builder-list',
    layout: CoreLayout,
    tab: 'builders',
    icon: 'build',
    private: true,
    showInMenu: true,
    component: BuilderList,
    path: '/builders',
    rolesWithAccess: ['superadmin'],
    defaultMenu: 'compact',
    showToolbar: true,
    showFooter: false,
    maxWidth: 'lg',
  },
  {
    title: 'Create Builder',
    id: 'builder-creator',
    layout: CoreLayout,
    tab: 'builders',
    private: true,
    showInMenu: false,
    component: BuilderCreate,
    path: '/builders/create',
    rolesWithAccess: ['superadmin'],
    defaultMenu: 'compact',
    showToolbar: true,
    showFooter: false,
    maxWidth: 'md',
  },
  {
    title: 'Read Builder',
    id: 'builder-reader',
    layout: CoreLayout,
    tab: 'builders',
    private: true,
    showInMenu: false,
    component: BuilderReader,
    path: '/builders/:builderId',
    rolesWithAccess: ['superadmin'],
    defaultMenu: 'compact',
    showToolbar: true,
    showFooter: false,
    maxWidth: 'md',
  },
  {
    title: 'Users',
    id: 'users-list',
    layout: CoreLayout,
    tab: 'users',
    icon: 'person',
    private: true,
    showInMenu: true,
    component: UserList,
    path: '/users',
    rolesWithAccess: ['admin', 'superadmin'],
    defaultMenu: 'compact',
    showToolbar: true,
    showFooter: false,
    maxWidth: 'lg',
  },
  {
    title: 'Create User',
    id: 'user-creator',
    layout: CoreLayout,
    tab: 'users',
    private: true,
    showInMenu: false,
    component: UserCreate,
    path: '/users/create',
    rolesWithAccess: ['admin', 'superadmin'],
    defaultMenu: 'compact',
    showToolbar: true,
    showFooter: false,
    maxWidth: 'md',
  },
  {
    title: 'Read User',
    id: 'users-reader',
    layout: CoreLayout,
    tab: 'users',
    private: true,
    showInMenu: false,
    component: UserReader,
    path: '/users/:userId',
    rolesWithAccess: ['admin', 'superadmin'],
    defaultMenu: 'compact',
    showToolbar: true,
    showFooter: false,
    maxWidth: 'md',
  },
  {
    title: 'Admins',
    id: 'admins-list',
    layout: CoreLayout,
    tab: 'admins',
    icon: 'engineering',
    private: true,
    showInMenu: true,
    component: AdminList,
    path: '/admins',
    rolesWithAccess: ['admin', 'superadmin'],
    defaultMenu: 'compact',
    showToolbar: true,
    showFooter: false,
    maxWidth: 'lg',
  },
  {
    title: 'Create Admin',
    id: 'admin-creator',
    layout: CoreLayout,
    tab: 'admins',
    private: true,
    showInMenu: false,
    component: AdminCreate,
    path: '/admins/create',
    rolesWithAccess: ['admin', 'superadmin'],
    defaultMenu: 'compact',
    showToolbar: true,
    showFooter: false,
    maxWidth: 'md',
  },
  {
    title: 'Read Admin',
    id: 'admins-reader',
    layout: CoreLayout,
    tab: 'admins',
    private: true,
    showInMenu: false,
    component: AdminReader,
    path: '/admins/:adminId',
    rolesWithAccess: ['admin', 'superadmin'],
    defaultMenu: 'compact',
    showToolbar: true,
    showFooter: false,
    maxWidth: 'md',
  },
  {
    title: 'Models',
    id: 'model-list',
    layout: CoreLayout,
    tab: 'models',
    icon: 'design_services',
    private: true,
    showInMenu: true,
    component: ModelList,
    path: '/models',
    rolesWithAccess: ['admin', 'superadmin'],
    defaultMenu: 'compact',
    showToolbar: true,
    showFooter: false,
    maxWidth: 'lg',
  },
  {
    title: 'Create Model',
    id: 'model-creator',
    layout: CoreLayout,
    tab: 'models',
    private: true,
    showInMenu: false,
    component: ModelCreate,
    path: '/models/create',
    rolesWithAccess: ['admin', 'superadmin'],
    defaultMenu: 'compact',
    showToolbar: true,
    showFooter: false,
    maxWidth: false,
  },
  {
    title: 'Model Reader',
    id: 'model-reader',
    layout: CoreLayout,
    tab: 'models',
    private: true,
    showInMenu: false,
    component: ModelReader,
    path: '/models/:modelId',
    rolesWithAccess: ['admin', 'superadmin'],
    defaultMenu: 'compact',
    showToolbar: true,
    showFooter: false,
    maxWidth: false,
  },
  {
    title: 'Terms',
    id: 'terms',
    layout: CoreLayout,
    private: null,
    showInMenu: false,
    component: TermsPage,
    path: '/terms',
    rolesWithAccess: [],
    defaultMenu: 'none',
    showToolbar: true,
    showFooter: false,
    maxWidth: 'lg',
  },
  {
    title: 'Privacy',
    id: 'privacy',
    layout: CoreLayout,
    private: null,
    showInMenu: false,
    component: PrivacyPage,
    path: '/privacy',
    rolesWithAccess: [],
    defaultMenu: 'none',
    showToolbar: true,
    showFooter: false,
    maxWidth: 'lg',
  },
  {
    title: 'Builder',
    id: 'builder',
    layout: CoreLayout,
    tab: 'builder',
    icon: 'handyman',
    private: true,
    showInMenu: true,
    component: BuilderPage,
    path: '/builder',
    rolesWithAccess: ['admin', 'superadmin'],
    defaultMenu: 'compact',
    showToolbar: true,
    showFooter: false,
    maxWidth: 'md',
  },
  {
    title: 'Profile',
    id: 'profile',
    layout: CoreLayout,
    tab: 'profile',
    icon: 'settings',
    private: true,
    showInMenu: true,
    component: ProfilePage,
    path: '/profile',
    rolesWithAccess: ['admin', 'user', 'superadmin'],
    defaultMenu: 'compact',
    showToolbar: true,
    showFooter: false,
    maxWidth: 'md',
  },
];
