import { createTheme } from '@material-ui/core/styles';

export default ({ primaryColor = '#000000', secondaryColor = '#000000' }) => {
  return createTheme({
    typography: {
      fontFamily: "'IBM Plex Sans', sans-serif",
      useNextVariants: true,
    },
    palette: {
      primary: {
        main: primaryColor,
      },
      secondary: {
        main: secondaryColor,
      },
    },
    overrides: {
      MuiButton: {
        root: {
          fontWeight: 600,
        },
      },
      MuiSelect: {
        selectMenu: {
          whiteSpace: 'normal',
        },
      },
      MuiInput: {},
      MuiCard: {},
    },
  });
};
