import { updateView } from 'actions';

const updateLayout = (props) => {
  const { dispatch, route } = props;

  let updateMenu = route.defaultMenu;

  updateMenu = 'fixed';

  if (route.defaultMenu === 'none') {
    updateMenu = 'none';
  }

  dispatch(
    updateView(route.showToolbar, route.showFooter, route.maxWidth, updateMenu)
  );

  return true;
};

export default updateLayout;
