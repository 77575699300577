import {
  removeNodeAtPath,
  changeNodeAtPath,
  addNodeUnderParent,
  find,
} from 'react-sortable-tree';
import _, {
  get,
  cloneDeep,
  omit,
  isObject,
  isString,
  filter,
  size,
  map,
  isUndefined,
  isArray,
  pick,
  set,
  assign,
  has,
} from 'lodash';
import nanoId from 'utils/nanoId';
import html2canvas from 'html2canvas';

window.html2canvas = html2canvas;

function base64ToBlob(base64) {
  //check if is base64
  if (
    typeof base64 !== 'string' ||
    !/^data:([a-zA-Z0-9-+\/]+);base64,([a-zA-Z0-9+/=]+)$/.test(val)
  ) {
    return false;
  }

  // Extract the MIME type from the base64 string
  const mimeTypeMatch = /^data:(.*);base64,/.exec(base64);
  const mimeType = mimeTypeMatch ? mimeTypeMatch[1] : null;

  // Check if a valid MIME type was extracted
  if (!mimeType) {
    return false;
  }

  // Decode the base64 string into a Uint8Array
  let decodedData;
  try {
    decodedData = new Uint8Array(
      atob(base64.split(',')[1])
        .split('')
        .map((c) => c.charCodeAt(0))
    );
  } catch (e) {
    return false;
  }

  // Convert the binary data to an ArrayBuffer
  const arrayBuffer = decodedData.buffer;

  // Create a Blob object with the binary data and MIME type
  return new Blob([arrayBuffer], { type: mimeType });
}

window.getColorAsHexCode = function (color) {
  if (Array.isArray(color)) {
    // Convert RGB values to hex
    let hex = color
      .slice(0, 3)
      .map((component) => {
        return component.toString(16).padStart(2, '0');
      })
      .join('');

    // If alpha channel is present, convert to hex
    if (color.length === 4) hex += color[3].toString(16).padStart(2, '0');

    color = `#${hex}`;
  } else if (
    (typeof color === 'string' || color instanceof String) &&
    color.length == 10
  ) {
    color = '#' + color.substring(2, 8);
  }

  return color;
};

export const configlab = ({ values, setValues, section, data }) => ({
  setModel: (model) => {
    setValues(cloneDeep(set(values, 'general.defaultValue', model)));
    window?.sdApi?.scene?.resume();
  },
  builder: (toPick) => {
    if (isUndefined(toPick)) {
      return data;
    }

    if (isString(toPick)) {
      return get(pick(data, toPick), toPick, undefined);
    }

    if (isArray(toPick)) {
      return pick(data, toPick);
    }
  },
  model: (toPick) => {
    if (isUndefined(toPick)) {
      return data;
    }

    if (isString(toPick)) {
      return get(pick(data, toPick), toPick, undefined);
    }

    if (isArray(toPick)) {
      return pick(data, toPick);
    }
  },
  get: (searchQuery) => {
    const getNodeKey = ({ treeIndex }) => treeIndex;
    const treeData = get(values, section, []);

    const items = find({
      getNodeKey,
      treeData,
      searchQuery,
      searchMethod: ({ node, searchQuery }) => {
        if (isString(searchQuery)) {
          return node.id.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1;
        }
        if (isObject(searchQuery)) {
          return size(filter([node], searchQuery)) > 0;
        }
      },
    });

    const mapItems = map(get(items, 'matches', []), (item) => ({
      data: (toPick) => {
        const dataBase = {
          ...get(item, 'node', {}),
          path: get(item, 'path', []),
        };

        if (isUndefined(toPick)) {
          return dataBase;
        }

        if (isString(toPick)) {
          return get(pick(dataBase, toPick), toPick, undefined);
        }

        if (isArray(toPick)) {
          return pick(dataBase, toPick);
        }
      },
      action: async (actionType) => {
        const data = get(item, 'node', {});

        try {
          if (data.action) {
            //if it is an upload type item, temporarily change the item value to a blob so it can be used in tha action
            var isUpload = data.type === 'upload_button';
            if (isUpload) {
              //test if it is a URL
              if (/^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i.test(data.value)) {
                const response = await fetch(data.value);

                // Check if the request was successful
                if (response.ok) {
                  window.menu(data.id).update({
                    value: await response.blob(),
                  });
                }
              } else {
                //try to get the blob from a base 64
                var blob = base64ToBlob(data.value);
                if (blob) {
                  window.menu(data.id).update({
                    value: blob,
                  });
                }
              }
            }

            // eslint-disable-next-line
            await window.eval(`( async ()=>{
              try{
                const item = ${section}("${data.id}");let value = item.data("value");if(typeof value === "number")value=Number(value);
                ${data.action}
              } catch(e) {console.error(e);}
            })()`);

            //if it is an upload type item, set back the value to the original
            if (isUpload) {
              window.menu(data.id).update({
                value: data.value,
              });
            }

            return;
          }
          if (data.onChangeCommitted && actionType == 'onChangeCommitted') {
            // eslint-disable-next-line
            await window.eval(`( async ()=>{
              try{
                const item = ${section}("${data.id}");let value = item.data("value");if(typeof value === "number")value=Number(value);
                ${data.onChangeCommitted}
              } catch(e) {console.error(e);}
            })()`);

            return;
          } else if (data.onChange) {
            // eslint-disable-next-line
            await window.eval(`( async ()=>{
              try{
                const item = ${section}("${data.id}");let value = item.data("value");if(typeof value === "number")value=Number(value);
                ${data.onChange}
              } catch(e) {console.error(e);}
            })()`);

            return;
          }
          if (!data.action && data.options) {
            // eslint-disable-next-line

            window.eval(`( async ()=>{
              try{
                const item = ${section}("${
              data.id
            }");let value = item.data("value");if(typeof value === "number")value=Number(value);
                ${get(
                  filter(data.options, { value: data.value }),
                  '[0].action'
                )}
              } catch(e) {console.error(e);}
            })()`);

            if (data?.type === 'switch_scene') {
              scene(data?.id).update({
                value: !data?.value,
              });
            }

            return;
          }
        } catch (e) {
          console.error(e);
        }

        return false;
      },
      update: (toUpdate) => {
        let nodeType = get(item, 'node.type');
        let updateValue = has(toUpdate, 'value');
        let updateOptions = has(item, 'node.options');
        let isSliderPicker =
          nodeType == 'slider_picker' || nodeType == 'slider_picker_v2';

        if (nodeType == 'color_picker' && updateValue)
          toUpdate.value = getColorAsHexCode(toUpdate.value);

        if (
          !isSliderPicker &&
          updateValue &&
          updateOptions &&
          _.find(get(item, 'node.options'), { value: toUpdate.value }) ===
            undefined
        ) {
          // undefined value
          console.log("Can't update value to undefined");
          toUpdate.value = null;
        }

        const newNode = cloneDeep(
          assign(
            get(item, 'node', {}),
            omit(toUpdate, ['id', 'type', 'notChild', 'children', 'options'])
          )
        );

        if (
          (nodeType == 'slider' || nodeType == 'slider_v2') &&
          (updateValue ||
            has(toUpdate, 'step') ||
            has(toUpdate, 'minValue') ||
            has(toUpdate, 'maxValue'))
        ) {
          if (Number(newNode.value) >= Number(newNode.maxValue))
            newNode.value = newNode.maxValue;
          else if (Number(newNode.value) <= Number(newNode.minValue))
            newNode.value = newNode.minValue;
          else if (
            !Number.isInteger((newNode.value - newNode.minValue) / newNode.step)
          ) {
            newNode.value =
              Math.round((newNode.value - newNode.minValue) / newNode.step) *
                newNode.step +
              Number(newNode.minValue);
          }
          var textField = document.getElementsByName(
            newNode.id + '_textField'
          )[0];
          if (textField) textField.value = newNode.value;
        } else if (isSliderPicker && updateValue) {
          newNode.value = newNode.options
            .map((obj) => obj.value)
            .reduce((closest, current) => {
              return Math.abs(current - newNode.value) <
                Math.abs(closest - newNode.value)
                ? current
                : closest;
            });
          var textField = document.getElementsByName(
            newNode.id + '_textField'
          )[0];
          if (textField) textField.value = newNode.value;
        }

        setValues(
          cloneDeep(
            set(
              values,
              section,
              changeNodeAtPath({
                treeData,
                path: get(item, 'path', []),
                getNodeKey,
                newNode,
              })
            )
          )
        );
      },
      updateOption: (index, toUpdate) => {
        if (!has(item, 'node.options')) {
          return false;
        }

        const option = cloneDeep(
          assign(get(item, `node.options.[${index}]`, {}), toUpdate)
        );

        const newNode = cloneDeep(set(item, `node.options.[${index}]`, option));

        setValues(
          cloneDeep(
            set(
              values,
              section,
              changeNodeAtPath({
                treeData,
                path: get(item, 'path', []),
                getNodeKey,
                newNode: newNode.node,
              })
            )
          )
        );
        const appToGet = get(window, section);
        appToGet(get(item, 'node.id')).update({
          change: !get(item, 'node.change'),
        });
      },
      delete: () => {
        setValues(
          cloneDeep(
            set(
              values,
              section,
              removeNodeAtPath({
                treeData,
                path: get(item, 'path', []),
                getNodeKey,
              })
            )
          )
        );
      },
      duplicate: () => {
        const path = get(item, 'path', []);
        const newIds = (data) => {
          if (size(data.children) > 0) {
            return {
              ...data,
              id: nanoId(),
              children: map(data.children, (child) => newIds(child)),
            };
          } else {
            return {
              ...data,
              id: nanoId(),
            };
          }
        };

        const node = cloneDeep(get(item, 'node', {}));

        const newNode = {
          ...newIds(node),
          label: `${get(node, 'label')} - Copy`,
        };

        setValues(
          cloneDeep(
            set(
              values,
              section,
              addNodeUnderParent({
                treeData,
                parentKey: get(path, `[${size(path) - 2}]`, null),
                expandParent: true,
                getNodeKey,
                newNode,
                addAsFirstChild: false,
                rootKey: 0,
              }).treeData
            )
          )
        );

        switch (section) {
          case 'menu':
            return window.menu(newNode.id);
          case 'scene':
            return window.scene(newNode.id);
          case 'summary.customSummary':
            return window.summary(newNode.id);
          case 'form':
            return window.form(newNode.id);
        }
      },
    }));

    return size(mapItems) <= 1 ? get(mapItems, '[0]') : mapItems;
  },
});
