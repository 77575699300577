import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@material-ui/core';

const LinkCell = ({ link, label, target = '_self' }) => {
  return label ? (
    <Button
      component={Link}
      to={link}
      target={target}
      style={{
        textTransform: 'none',
        padding: '0px 5px',
        borderRadius: '14px',
      }}
      color="primary"
      variant="outlined"
      size="small"
    >
      {label}
    </Button>
  ) : (
    '-'
  );
};

export default LinkCell;
