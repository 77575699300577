import React from 'react';
import Link from 'components/Table/components/Link';

export const filters = ({ builderId }) => ({
  admin: [
    ['role', '==', 'user'],
    ['builderId', '==', builderId],
  ],
  superadmin: [['role', '==', 'user']],
});

export const view = {
  admin: [
    {
      empty: true,
    },
    {
      id: 'uid',
      numeric: false,
      disablePadding: false,
      label: 'User ID',
    },
    {
      id: 'firstName',
      numeric: false,
      disablePadding: false,
      label: 'First Name',
    },
    {
      id: 'lastName',
      numeric: false,
      disablePadding: false,
      label: 'Last Name',
    },
    {
      id: 'email',
      numeric: false,
      disablePadding: false,
      label: 'Email',
    },
    {
      label: 'Actions',
      empty: true,
    },
  ],
  superadmin: [
    {
      empty: true,
    },
    {
      id: 'uid',
      numeric: false,
      disablePadding: false,
      label: 'User ID',
    },
    {
      id: 'firstName',
      numeric: false,
      disablePadding: false,
      label: 'First Name',
    },
    {
      id: 'lastName',
      numeric: false,
      disablePadding: false,
      label: 'Last Name',
    },
    {
      id: 'email',
      numeric: false,
      disablePadding: false,
      label: 'Email',
    },
    {
      id: 'builderId',
      format: (builder) => (
        <Link label={builder} link={`/builders/${builder}`} />
      ),
      label: 'Builder ID',
    },
    {
      label: 'Actions',
      empty: true,
    },
  ],
};

export const download = {
  admin: ['uid', 'firstName', 'lastName', 'email', 'phone'],
  superadmin: ['uid', 'firstName', 'lastName', 'email', 'phone', 'builderId'],
};

export const pathCollection = 'users';

export const createView = [
  {
    xs: 12,
    key: 'photoProfile',
    label: 'Avatar',
    md: 12,
    type: 'image',
    circle: true,
  },
  {
    xs: 12,
    key: 'email',
    label: 'Email',
    type: 'email',
    placeholder: 'john.doe@acme.com',
    required: true,
  },
  {
    xs: 12,
    key: 'confirmEmail',
    label: 'Confirm Email',
    placeholder: 'john.doe@acme.com',
    type: 'email',
    required: true,
  },
  {
    xs: 12,
    key: 'password',
    label: 'Password',
    type: 'text',
    placeholder: '******',
    inputProps: {
      minLength: 6,
    },
    required: true,
  },
  {
    xs: 12,
    key: 'confirmPassword',
    label: 'Confirm Password',
    type: 'text',
    placeholder: '******',
    inputProps: {
      minLength: 6,
    },
    required: true,
  },
  {
    xs: 12,
    key: 'firstName',
    label: 'First Name',
    type: 'text',
    placeholder: 'John',
    required: true,
  },
  {
    xs: 12,
    key: 'lastName',
    label: 'Last Name',
    type: 'text',
    placeholder: 'Doe',
    required: true,
  },
  {
    xs: 12,
    key: 'phone',
    label: 'Phone Number',
    type: 'number',
    placeholder: '123456789',
  },
  {
    xs: 12,
    key: 'companyName',
    label: 'Company Name',
    type: 'text',
    placeholder: 'ACME Inc.',
  },
];

export const editView = {
  user: [
    {
      xs: 12,
      key: 'photoProfile',
      label: 'Avatar',
      md: 12,
      type: 'image',
      circle: true,
    },
    {
      xs: 12,
      key: 'uid',
      md: 6,
      label: 'User ID',
      type: 'id',
      readOnly: true,
    },
    {
      xs: 12,
      key: 'email',
      label: 'Email',
      type: 'email',
      readOnly: true,
    },
    {
      xs: 12,
      key: 'phone',
      label: 'Phone Number',
      type: 'number',
      placeholder: '123456789',
    },
    {
      xs: 12,
      key: 'firstName',
      label: 'First Name',
      type: 'text',
      placeholder: 'John',
      required: true,
    },
    {
      xs: 12,
      key: 'lastName',
      label: 'Last Name',
      type: 'text',
      placeholder: 'Doe',
      required: true,
    },
    {
      xs: 12,
      key: 'companyName',
      label: 'Company Name',
      type: 'text',
      placeholder: 'ACME Inc.',
    },
  ],
  admin: [
    {
      xs: 12,
      key: 'photoProfile',
      label: 'Avatar',
      md: 12,
      type: 'image',
      circle: true,
    },
    {
      xs: 12,
      key: 'uid',
      md: 6,
      label: 'User ID',
      type: 'id',
      readOnly: true,
    },
    {
      xs: 12,
      key: 'email',
      label: 'Email',
      type: 'email',
      readOnly: true,
    },
    {
      xs: 12,
      key: 'phone',
      label: 'Phone Number',
      type: 'number',
      placeholder: '123456789',
    },
    {
      xs: 12,
      key: 'firstName',
      label: 'First Name',
      type: 'text',
      placeholder: 'John',
      required: true,
    },
    {
      xs: 12,
      key: 'lastName',
      label: 'Last Name',
      type: 'text',
      placeholder: 'Doe',
      required: true,
    },
    {
      xs: 12,
      key: 'companyName',
      label: 'Company Name',
      type: 'text',
      placeholder: 'ACME Inc.',
    },
  ],
  superadmin: [
    {
      xs: 12,
      key: 'photoProfile',
      label: 'Avatar',
      md: 12,
      type: 'image',
      circle: true,
    },
    {
      xs: 12,
      key: 'uid',
      md: 6,
      label: 'User ID',
      type: 'id',
      readOnly: true,
    },
    {
      xs: 12,
      key: 'builderId',
      md: 6,
      label: 'Builder ID',
      type: 'id',
      readOnly: true,
    },
    {
      xs: 12,
      key: 'email',
      label: 'Email',
      type: 'email',
      readOnly: true,
    },
    {
      xs: 12,
      key: 'phone',
      label: 'Phone Number',
      type: 'number',
      placeholder: '123456789',
    },
    {
      xs: 12,
      key: 'firstName',
      label: 'First Name',
      type: 'text',
      placeholder: 'John',
      required: true,
    },
    {
      xs: 12,
      key: 'lastName',
      label: 'Last Name',
      type: 'text',
      placeholder: 'Doe',
      required: true,
    },
    {
      xs: 12,
      key: 'companyName',
      label: 'Company Name',
      type: 'text',
      placeholder: 'ACME Inc.',
    },
  ],
};

export const readView = {
  user: [
    {
      xs: 12,
      key: 'photoProfile',
      label: 'Avatar',
      md: 12,
      type: 'image',
      circle: true,
    },
    {
      xs: 12,
      key: 'uid',
      label: 'User ID',
      type: 'id',
      readOnly: true,
    },
    {
      xs: 12,
      key: 'email',
      label: 'Email',
      type: 'email',
      readOnly: true,
    },
    {
      xs: 12,
      key: 'firstName',
      label: 'First Name',
      type: 'text',
      placeholder: 'John',
      required: true,
    },
    {
      xs: 12,
      key: 'lastName',
      label: 'Last Name',
      type: 'text',
      placeholder: 'Doe',
      required: true,
    },
    {
      xs: 12,
      key: 'phone',
      label: 'Phone Number',
      type: 'number',
      placeholder: '123456789',
    },
    {
      xs: 12,
      key: 'companyName',
      label: 'Company Name',
      type: 'text',
      placeholder: 'ACME Inc.',
    },
  ],
  admin: [
    {
      xs: 12,
      key: 'photoProfile',
      label: 'Avatar',
      md: 12,
      type: 'image',
      circle: true,
    },
    {
      xs: 12,
      key: 'uid',
      label: 'User ID',
      type: 'id',
      readOnly: true,
    },
    {
      xs: 12,
      key: 'email',
      label: 'Email',
      type: 'email',
      readOnly: true,
    },
    {
      xs: 12,
      key: 'firstName',
      label: 'First Name',
      type: 'text',
      placeholder: 'John',
      required: true,
    },
    {
      xs: 12,
      key: 'lastName',
      label: 'Last Name',
      type: 'text',
      placeholder: 'Doe',
      required: true,
    },
    {
      xs: 12,
      key: 'phone',
      label: 'Phone Number',
      type: 'number',
      placeholder: '123456789',
    },
    {
      xs: 12,
      key: 'companyName',
      label: 'Company Name',
      type: 'text',
      placeholder: 'ACME Inc.',
    },
  ],
  superadmin: [
    {
      xs: 12,
      key: 'photoProfile',
      label: 'Avatar',
      md: 12,
      type: 'image',
      circle: true,
    },
    {
      xs: 12,
      key: 'uid',
      md: 6,
      label: 'User ID',
      type: 'id',
      readOnly: true,
    },
    {
      xs: 12,
      key: 'builderId',
      md: 6,
      label: 'Builder ID',
      type: 'id',
      readOnly: true,
    },
    {
      xs: 12,
      key: 'email',
      label: 'Email',
      type: 'email',
      readOnly: true,
    },
    {
      xs: 12,
      key: 'firstName',
      label: 'First Name',
      type: 'text',
      placeholder: 'John',
      required: true,
    },
    {
      xs: 12,
      key: 'lastName',
      label: 'Last Name',
      type: 'text',
      placeholder: 'Doe',
      required: true,
    },
    {
      xs: 12,
      key: 'phone',
      label: 'Phone Number',
      type: 'number',
      placeholder: '123456789',
    },
    {
      xs: 12,
      key: 'companyName',
      label: 'Company Name',
      type: 'text',
      placeholder: 'ACME Inc.',
    },
  ],
};
