import { withStyles } from '@material-ui/core/styles';

export default withStyles((theme) => ({
  table: {
    minWidth: 500,
    root: {
      boxShadow: 'none',
    },
  },
  row: {
    '&:hover': {
      backgroundColor: theme.palette.grey[100],
    },
  },
}));
