import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import styles from './styles';
import { Link } from 'react-router-dom';
import {
  Icon,
  Button,
  Grid,
  Typography,
  TextField,
  IconButton,
  Box,
} from '@material-ui/core';
import updateLayout from 'utils/updateLayout';
import useIsMounted from 'ismounted';
import Table from 'components/Table';
import { get } from 'lodash';
import { downloadData } from 'utils/scripts';
import { filters, download, view } from './resource';

const UserList = ({ classes, userData, dispatch, route }) => {
  const [search, setSearch] = React.useState('');
  const isMounted = useIsMounted();

  useEffect(() => {
    updateLayout({ dispatch, route });
  }, [dispatch, route]);

  const filter = filters(userData)[userData.role];
  const dataToShow = download[userData.role];
  const tableView = view[userData.role];

  return (
    <div className={classes.root}>
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        spacing={3}
      >
        <Grid item>
          <Typography variant="h4">
            <strong>Users</strong>
          </Typography>
        </Grid>

        <Grid item>
          <Box display="flex" flexDirection="row">
            <TextField
              value={search}
              placeholder="Search"
              size="small"
              variant="outlined"
              InputProps={{ style: { height: 42, marginTop: 2 } }}
              onChange={(event) =>
                isMounted.current && setSearch(get(event, 'target.value', ''))
              }
            />
            <Box mr={2} />
            <Button
              variant="contained"
              size="large"
              onClick={() =>
                downloadData({
                  collectToDownload: `users`,
                  filter,
                  dataToShow,
                })
              }
            >
              Download
            </Button>
            <Box mr={2} />
            <Button
              variant="contained"
              color="primary"
              size="large"
              component={Link}
              to={`users/create`}
            >
              Create User
            </Button>
          </Box>
        </Grid>
      </Grid>

      <Box height={20} />

      <Table
        collection={'/users'}
        search={search}
        pick={['uid']}
        labelRowsPerPage="Users per page"
        filter={filter}
        orderBy="firstName"
        headCells={tableView}
        firstCell={<Icon className={classes.iconItem}>person</Icon>}
        Actions={(row) => (
          <IconButton component={Link} to={`users/${row.uid}`}>
            <Icon>edit</Icon>
          </IconButton>
        )}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  userData: state.auth.userData,
});

export default connect(mapStateToProps)(styles(UserList));
