import { withStyles } from '@material-ui/core/styles';

export default withStyles((theme) => ({
  row: {
    textDecoration: 'none',
    '&:nth-of-type(odd)': {
      backgroundColor: `${theme.palette.primary.light}08`,
    },
    '&:hover': {
      backgroundColor: `${theme.palette.primary.light}10`,
    },
  },
}));
