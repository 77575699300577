import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/performance';
import { firebaseConfig, firebaseConfigDev } from '../config';
import includes from 'lodash/includes';

const devHostname = [
  'localhost',
  'dev.configlab.co',
  'configlab-dev.firebaseapp.com',
  'configlab-dev.web.app',
  'configlab.co',
];
const hostname = window.location.hostname;
const config = includes(devHostname, hostname)
  ? firebaseConfigDev
  : firebaseConfig;

// Enable Real Time Database emulator if environment variable is set
if (process.env.REACT_APP_FIREBASE_DATABASE_EMULATOR_HOST) {
  config.databaseURL = `http://${process.env.REACT_APP_FIREBASE_DATABASE_EMULATOR_HOST}?ns=${config.projectId}`;
  console.debug(`RTDB emulator enabled: ${config.databaseURL}`); // eslint-disable-line no-console
}

firebase.initializeApp(config);

// Enable Firestore emulator if environment variable is set
if (process.env.REACT_APP_FIRESTORE_EMULATOR_HOST) {
  /* eslint-disable no-console */
  console.debug(
    `Firestore emulator enabled: ${process.env.REACT_APP_FIRESTORE_EMULATOR_HOST}`
  );
  /* eslint-enable no-console */
  firebase.firestore().settings({
    merge: true,
    host: process.env.REACT_APP_FIRESTORE_EMULATOR_HOST,
    ssl: false,
  });
}

// fixing undefined values
firebase.firestore().settings({ merge: true, ignoreUndefinedProperties: true });

if (process.env.REACT_APP_FUNCTIONS_EMULATOR_HOST) {
  firebase.functions().useEmulator('localhost', 5000);
}
